<template>
  <div>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="10">
        <h3>
          {{ $t('settings.page.pageSeo.seoSection') }}
        </h3>
        <hr>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>
          {{ $t('settings.page.pageSeo.titleLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-checkbox
          id="useTitleAsSeoTitle"
          v-model="useTitleAsSeoTitle"
          name="useTitleAsSeoTitle"
          size="lg"
          :value="true"
          :unchecked-value="false"
          @change="clickShowSeoTitleCheckbox"
        >
          {{ $t('settings.page.pageSeo.useTitleAsSeoTitle') }}
        </b-form-checkbox>
        <b-form-text class="mb-4">
          {{ $t('settings.page.pageSeo.useTitleAsSeoTitleDesc') }}
        </b-form-text>
        <div v-if="!useTitleAsSeoTitle">
          <b-form-input
            id="seo-title"
            v-model="seoDataComponent.og_title"
            debounce="500"
            @input="$emit('updateSOSettings', 'og_title', $event)"
          ></b-form-input>
          <b-form-text>
            {{ $t('settings.page.pageSeo.titleDesc') }}
          </b-form-text>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>
          {{ $t('settings.page.pageSeo.descriptionLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-checkbox
          id="useDescAsSeoDesc"
          v-model="useDescAsSeoDesc"
          name="useDescAsSeoDesc"
          size="lg"
          :value="true"
          :unchecked-value="false"
          @change="clickShowSeoDescCheckbox"
        >
          {{ $t('settings.page.pageSeo.useTitleAsSeoDescription') }}
        </b-form-checkbox>
        <b-form-text class="mb-4">
          {{ $t('settings.page.pageSeo.useTitleAsSeoDescriptionDesc') }}
        </b-form-text>
        <div v-if="!useDescAsSeoDesc">
          <b-form-textarea
            id="page-description"
            v-model="seoDataComponent.og_description"
            rows="3"
            max-rows="6"
            debounce="500"
            @input="$emit('updateSOSettings', 'og_description', $event)"
          ></b-form-textarea>
          <b-form-text>
            {{ $t('settings.page.pageSeo.descriptionDesc') }}
          </b-form-text>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>
          {{ $t('settings.page.pageSeo.sharingImageLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-checkbox
          id="showSharingImage"
          v-model="showSharingImage"
          name="showSharingImage"
          size="lg"
          :value="false"
          :unchecked-value="true"
          @change="clickSharingImageCheckbox"
        >
          {{ $t('settings.page.pageSeo.useMainSharingImage') }}
        </b-form-checkbox>
        <b-form-text class="mb-4">
          {{ $t('settings.page.pageSeo.useMainSharingImageDesc') }}
        </b-form-text>
        <div v-if="showSharingImage">
          <image-upload
            :image-object="sharingImage"
            @upload-image-object="updateUploadImageObject"
          />
          <b-form-text>
            {{ $t('settings.page.pageSeo.sharingImageDesc') }}
          </b-form-text>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>
          {{ $t('settings.page.pageSeo.canonicalLinkLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-input
          id="canonical-link"
          v-model="seoDataComponent.canonical_url"
          debounce="500"
          @input="$emit('updateSOSettings', 'canonical_url', $event)"
        ></b-form-input>
        <b-form-text>
          {{ $t('settings.page.pageSeo.canonicalLinkDesc') }}
        </b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-4 pb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>
          {{ $t('settings.page.pageSeo.hreflangLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <b-form-textarea
          id="hreflang"
          v-model="seoDataComponent.hreflang"
          rows="3"
          max-rows="6"
          debounce="500"
          @input="$emit('updateSOSettings', 'hreflang', $event)"
        ></b-form-textarea>
        <b-form-text
          v-html="$t('settings.page.pageSeo.hreflangDesc')"
        ></b-form-text>
      </b-col>
    </b-row>
    <item-language
      :language-id="seoDataComponent.language_id"
      @language-changed="$emit('updateSOSettings', 'language', $event)"
    />
  </div>
</template>

<script>
import SetButtonDisabledInCache from '@/graphQlQueries/mutations/setButtonDisabledInCache';

export default {
  name: 'PageSettingsSeo',
  components: {
    ImageUpload: () => import('@/components/helper/ImageUpload.vue'),
    ItemLanguage: () => import('@/components/settings/ItemLanguage.vue'),
  },
  data: () => ({
    showSharingImage: null,
    useTitleAsSeoTitle: null,
    useDescAsSeoDesc: null,
    seoDataComponent: {
      og_title: null,
      og_description: null,
      canonical_url: null,
      hreflang: null,
      og_images: null,
    },
  }),
  props: ['seoData'],
  computed: {
    sharingImage() {
      if (this.seoDataComponent.og_images && this.seoDataComponent.og_images.image) {
        return this.seoDataComponent.og_images.image;
      }
      return null;
    },
  },
  created() {
    this.seoDataComponent = {
      ...this.seoDataComponent,
      ...this.seoData,
    };
    this.useTitleAsSeoTitle = (this.seoDataComponent.og_title === null);
    this.useDescAsSeoDesc = (this.seoDataComponent.og_description === null);

    this.showSharingImage = !(this.seoDataComponent.og_images === null
      || (this.seoDataComponent.og_images && this.seoDataComponent.og_images?.image === null));

    // initially set disable state of the button on false
    this.$apollo.mutate({
      mutation: SetButtonDisabledInCache,
      variables: {
        isButtonDisabled: false,
      },
    });
  },
  methods: {
    updateUploadImageObject(imageObject) {
      this.seoDataComponent.og_images = { image: imageObject };
      this.$emit('updateSOSettings', 'og_images', this.seoDataComponent.og_images);
    },
    clickSharingImageCheckbox(value) {
      if (!value) {
        this.seoDataComponent.og_images = null;
        this.$emit('updateSOSettings', 'og_images', null);
      }
    },
    clickShowSeoTitleCheckbox(value) {
      if (value) {
        this.seoDataComponent.og_title = null;
        this.$emit('updateSOSettings', 'og_title', null);
      }
    },
    clickShowSeoDescCheckbox(value) {
      if (value) {
        this.seoDataComponent.og_description = null;
        this.$emit('updateSOSettings', 'og_description', null);
      }
    },
  },
};
</script>

<style scoped>

</style>
