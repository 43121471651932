var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "10" } }, [
            _c("h3", [
              _vm._v(
                " " + _vm._s(_vm.$t("settings.page.pageSeo.seoSection")) + " "
              )
            ]),
            _c("hr")
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [
              _vm._v(
                " " + _vm._s(_vm.$t("settings.page.pageSeo.titleLabel")) + " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c(
                "b-form-checkbox",
                {
                  attrs: {
                    id: "useTitleAsSeoTitle",
                    name: "useTitleAsSeoTitle",
                    size: "lg",
                    value: true,
                    "unchecked-value": false
                  },
                  on: { change: _vm.clickShowSeoTitleCheckbox },
                  model: {
                    value: _vm.useTitleAsSeoTitle,
                    callback: function($$v) {
                      _vm.useTitleAsSeoTitle = $$v
                    },
                    expression: "useTitleAsSeoTitle"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("settings.page.pageSeo.useTitleAsSeoTitle")
                      ) +
                      " "
                  )
                ]
              ),
              _c("b-form-text", { staticClass: "mb-4" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("settings.page.pageSeo.useTitleAsSeoTitleDesc")
                    ) +
                    " "
                )
              ]),
              !_vm.useTitleAsSeoTitle
                ? _c(
                    "div",
                    [
                      _c("b-form-input", {
                        attrs: { id: "seo-title", debounce: "500" },
                        on: {
                          input: function($event) {
                            return _vm.$emit(
                              "updateSOSettings",
                              "og_title",
                              $event
                            )
                          }
                        },
                        model: {
                          value: _vm.seoDataComponent.og_title,
                          callback: function($$v) {
                            _vm.$set(_vm.seoDataComponent, "og_title", $$v)
                          },
                          expression: "seoDataComponent.og_title"
                        }
                      }),
                      _c("b-form-text", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("settings.page.pageSeo.titleDesc")) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("settings.page.pageSeo.descriptionLabel")) +
                  " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c(
                "b-form-checkbox",
                {
                  attrs: {
                    id: "useDescAsSeoDesc",
                    name: "useDescAsSeoDesc",
                    size: "lg",
                    value: true,
                    "unchecked-value": false
                  },
                  on: { change: _vm.clickShowSeoDescCheckbox },
                  model: {
                    value: _vm.useDescAsSeoDesc,
                    callback: function($$v) {
                      _vm.useDescAsSeoDesc = $$v
                    },
                    expression: "useDescAsSeoDesc"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("settings.page.pageSeo.useTitleAsSeoDescription")
                      ) +
                      " "
                  )
                ]
              ),
              _c("b-form-text", { staticClass: "mb-4" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "settings.page.pageSeo.useTitleAsSeoDescriptionDesc"
                      )
                    ) +
                    " "
                )
              ]),
              !_vm.useDescAsSeoDesc
                ? _c(
                    "div",
                    [
                      _c("b-form-textarea", {
                        attrs: {
                          id: "page-description",
                          rows: "3",
                          "max-rows": "6",
                          debounce: "500"
                        },
                        on: {
                          input: function($event) {
                            return _vm.$emit(
                              "updateSOSettings",
                              "og_description",
                              $event
                            )
                          }
                        },
                        model: {
                          value: _vm.seoDataComponent.og_description,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.seoDataComponent,
                              "og_description",
                              $$v
                            )
                          },
                          expression: "seoDataComponent.og_description"
                        }
                      }),
                      _c("b-form-text", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("settings.page.pageSeo.descriptionDesc")
                            ) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("settings.page.pageSeo.sharingImageLabel")) +
                  " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c(
                "b-form-checkbox",
                {
                  attrs: {
                    id: "showSharingImage",
                    name: "showSharingImage",
                    size: "lg",
                    value: false,
                    "unchecked-value": true
                  },
                  on: { change: _vm.clickSharingImageCheckbox },
                  model: {
                    value: _vm.showSharingImage,
                    callback: function($$v) {
                      _vm.showSharingImage = $$v
                    },
                    expression: "showSharingImage"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("settings.page.pageSeo.useMainSharingImage")
                      ) +
                      " "
                  )
                ]
              ),
              _c("b-form-text", { staticClass: "mb-4" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("settings.page.pageSeo.useMainSharingImageDesc")
                    ) +
                    " "
                )
              ]),
              _vm.showSharingImage
                ? _c(
                    "div",
                    [
                      _c("image-upload", {
                        attrs: { "image-object": _vm.sharingImage },
                        on: {
                          "upload-image-object": _vm.updateUploadImageObject
                        }
                      }),
                      _c("b-form-text", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("settings.page.pageSeo.sharingImageDesc")
                            ) +
                            " "
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("settings.page.pageSeo.canonicalLinkLabel")) +
                  " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("b-form-input", {
                attrs: { id: "canonical-link", debounce: "500" },
                on: {
                  input: function($event) {
                    return _vm.$emit(
                      "updateSOSettings",
                      "canonical_url",
                      $event
                    )
                  }
                },
                model: {
                  value: _vm.seoDataComponent.canonical_url,
                  callback: function($$v) {
                    _vm.$set(_vm.seoDataComponent, "canonical_url", $$v)
                  },
                  expression: "seoDataComponent.canonical_url"
                }
              }),
              _c("b-form-text", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("settings.page.pageSeo.canonicalLinkDesc")) +
                    " "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4 pb-4" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
            _c("label", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("settings.page.pageSeo.hreflangLabel")) +
                  " "
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "7" } },
            [
              _c("b-form-textarea", {
                attrs: {
                  id: "hreflang",
                  rows: "3",
                  "max-rows": "6",
                  debounce: "500"
                },
                on: {
                  input: function($event) {
                    return _vm.$emit("updateSOSettings", "hreflang", $event)
                  }
                },
                model: {
                  value: _vm.seoDataComponent.hreflang,
                  callback: function($$v) {
                    _vm.$set(_vm.seoDataComponent, "hreflang", $$v)
                  },
                  expression: "seoDataComponent.hreflang"
                }
              }),
              _c("b-form-text", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("settings.page.pageSeo.hreflangDesc")
                  )
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("item-language", {
        attrs: { "language-id": _vm.seoDataComponent.language_id },
        on: {
          "language-changed": function($event) {
            return _vm.$emit("updateSOSettings", "language", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }